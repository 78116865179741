const SERVICES = {
  VASSIST_SERVICE: 'vAssistService',
  MOODLE_SERVICE: 'moodleService',
  MOODLE_FREE_VLEARN_SERVICE: 'moodleFreeVlearnService',
  FREE_ARTICLE_SERVICE: 'freeCourseArticle',
  PRIMEROOT_ENROLLMENT_SERVICE: 'PrimerootEnrollmentService',
  PRIMEROOT_ENROLLMENT_SERVICE_V2: 'PrimerootEnrollmentServiceV2',
  SPRINGBOOT_TO_NODE_APIS: 'SpringBootToNodeApis',
  VLEARN_PROFILE_SERVICE: 'VlearnProfileService',
};

const METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
};

const RESET_PASSWORD_TOKEN = '637357f81d9e84e2c1dfb4262f48b24d';

export { SERVICES, METHODS, RESET_PASSWORD_TOKEN };
